<template>
    <div>
        <el-result icon="info" title="" subTitle="正在开发中"></el-result>
    </div>
</template>

<script>

    export default {

    };
</script>

<style lang='scss'>

</style>
